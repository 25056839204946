/** @jsx jsx */
import { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { jsx } from "theme-ui";
import slugify from "slugify";

import Projects from "../templates/projects";

const PROJECTS_QUERY = graphql`
  query ProjectsQuery {
    wpPage(slug: { eq: "realizacie" }) {
      seo {
        metaDesc
        title
      }
      title
    }
    allWpPost(
      sort: { fields: slug, order: ASC }
      filter: { status: { eq: "publish" } }
    ) {
      edges {
        node {
          title
          slug
          excerpt
          featuredImage {
            node {
              # sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 512, quality: 100) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
    allWpCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`;

const comparePosts = (a, b) => {
  if (Number(a.slug.split("-")[1]) < Number(b.slug.split("-")[1])) {
    return -1;
  } else return 0;
};

const ProjectsPage = props => {
  const [searchResult, setSearchResult] = useState([]);
  const { wpPage, allWpPost, allWpCategory } = useStaticQuery(PROJECTS_QUERY);
  const searchParams = new URLSearchParams(props.location.search);
  // console.log(searchResult);
  let results =
    (searchResult.length && searchResult) || allWpPost?.edges?.map(x => x.node);

  results = [...new Set(results.map(x => x.slug.split("-")[0]))].flatMap(x =>
    results.filter(y => y.slug.includes(x)).sort(comparePosts)
  );

  if (props.location.search.length) {
    results = results.filter(
      y =>
        slugify(y.categories.nodes[0].name).toLowerCase() ===
        searchParams.get("kategoria")
    );
  }

  return (
    <Projects
      wpPage={wpPage}
      results={results}
      setSearchResult={setSearchResult}
    />
  );
};

export default ProjectsPage;
