/** @jsx jsx */
import { useState } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { jsx, Grid, Flex, Box, Card, Heading, Text } from "theme-ui";
import ResizeObserver from "react-resize-observer";
import BackgroundImage from "gatsby-background-image";
import slugify from "slugify";

import Layout from "../components/layout";
import Nav from "../components/nav";
// import SearchForm from "../components/search-form";
// import Image from "../components/image";

export default function Projects({
  wpPage,
  results,
  setSearchResult,
}): JSX.Element {
  const [rows, setRows] = useState(false);
  const [width, setWidth] = useState(null);

  // overview seo
  // const seo = {
  //   metaTitle: project.title,
  //   metaDescription: project.description,
  //   shareImage: project.images[0],
  //   article: true,
  // };

  //   const buttonSetColor = "black";
  //   // const buttonSetColor = "rgba(0, 0, 0, 0.1)";
  //   const borderColor = "0px 10px 30px 0px rgba(0, 0, 0, 0.1)";
  //   const iconSetColor = "black";

  return (
    <Layout seo={wpPage?.seo}>
      <ResizeObserver
        onResize={rect => {
          setWidth(rect.width);
        }}
        // onPosition={rect => {
        //   console.log("Moved. New position:", rect.left, "x", rect.top);
        // }}
      />

      {/* <Nav /> */}

      <Box sx={{ minHeight: "87vh" }}>
        {/* <Grid gap={2} columns={["1fr", "1fr 2fr"]}>
          <Box sx={{ px: 2 }}>
            <SearchForm
              allCategory={allWpCategory}
              setSearchResult={setSearchResult}
            />
          </Box> */}

        <Box sx={{ px: 3, py: 3 }}>
          <Flex sx={{ px: 3, mb: 3 }}>
            <Box>
              <StyledHeading>{wpPage.title}</StyledHeading>
            </Box>

            <Box mx="auto" />

            <Nav />

            {/* {width && width > 767 && (
                <Box>
                  <Button
                    onClick={() => setRows(true)}
                    sx={{
                      cursor: "pointer",
                      bg: rows ? buttonSetColor : "white",
                    }}
                  >
                    <FontAwesomeIcon
                      aria-hidden
                      icon={faList}
                      color={!rows ? iconSetColor : "white"}
                      size="1x"
                    />
                  </Button>

                  <Button
                    onClick={() => setRows(false)}
                    sx={{
                      cursor: "pointer",
                      bg: !rows ? buttonSetColor : "white",
                    }}
                  >
                    <FontAwesomeIcon
                      aria-hidden
                      icon={faThLarge}
                      color={rows ? iconSetColor : "white"}
                      size="1x"
                    />
                  </Button>
                </Box>
              )} */}
          </Flex>

          <Grid
            gap={2}
            columns={[1, null, rows ? 1 : (width && width > 1023 && 3) || 2]}
          >
            {results.map((sr, i) => (
              <Grid
                key={i}
                gap={2}
                columns={[
                  "1fr",
                  "1fr",
                  "1fr",
                  "1fr",
                  // !!sr.featuredImage?.node ? "1fr 1fr" : "1fr",
                ]}
                sx={{
                  pt: [4, 5],
                  pb: [3, 4],
                  px: [null, null, null, 5],
                  marginBottom: [5],
                  boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.1)",
                }}
              >
                {sr.featuredImage?.node && (
                  <Card>
                    <Link
                      to={`/${slugify(wpPage.title).toLowerCase()}/${sr.slug}`}
                      sx={{ textDecoration: "none" }}
                    >
                      <BackgroundImageLink
                        Tag="div"
                        fluid={
                          sr.featuredImage?.node?.localFile?.childImageSharp
                            .fluid
                        }
                      >
                        <ImageLinkOverlay>
                          <Text
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              transform: "translateY(350%)",
                              fontSize: [4, 5],
                            }}
                          >
                            Pozrieť realizáciu
                          </Text>
                        </ImageLinkOverlay>
                      </BackgroundImageLink>
                    </Link>
                  </Card>
                )}

                <Box
                  sx={{
                    mx: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    sx={{ p: 1, textAlign: "center" }}
                    dangerouslySetInnerHTML={{ __html: sr.excerpt }}
                  ></Text>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* </Grid> */}
      </Box>
    </Layout>
  );
}

const BackgroundImageLink = styled(BackgroundImage)`
  width: 100%;
  height: 50vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageLinkOverlay = styled(Box)`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  background-color: rgb(0, 0, 0, 0);
  color: transparent;

  :hover {
    background-color: rgb(1, 0, 0, 0.5);
    color: white;
  }
`;

const StyledHeading = styled(Heading)`
  text-transform: none;
  letter-spacing: 0;
  font-family: Graphik, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
    Arial, sans-serif;
  line-height: 1.68421;
  margin-bottom: 0.8rem;
  font-weight: 600;
`;

// const MenuLink = styled(Link)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   white-space: nowrap;

//   // padding: 7px 14px;

//   color: #404248;
//   text-decoration: none;

//   :focus,
//   :hover {
//     text-decoration: underline;
//     color: #0366d6;
//   }
// `;
